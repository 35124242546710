import { combineReducers } from 'redux'
import { userReducer, UserState, SetUser } from './user'
import { AddTenant, SetTenants, tenantReducer, TenantState, UpdateTenant, DeleteTenant } from './tenant'
import { User } from '../apis/api'
import { AddTheUser, SetUsers, UpdateTheUser, usersReducer } from './users'

export interface State {
  userState: UserState
  tenantState: TenantState[]
  usersState: User[]
}

export const reducers = combineReducers<State>({
  userState: userReducer,
  tenantState: tenantReducer,
  usersState: usersReducer
})

type Action =
  | SetUser
  | SetTenants
  | AddTenant
  | UpdateTenant
  | DeleteTenant
  | SetUsers
  | AddTheUser
  | UpdateTheUser

export type Dispatch = (action: Action) => void
