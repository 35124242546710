// @ts-nocheck - may need to be at the start of file
import React, { useEffect, useState, ReactNode } from 'react';
import styled from "@emotion/styled";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { connect, useSelector } from 'react-redux'
import { Dispatch, State } from '../reducers';
import { Tabs, getChartsByTenantId, updateCharts } from "../apis/api";
import { EditText } from 'react-edit-text';
import {
  Button,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography as MuiTypography,
  TextField,
  IconButton,
  Tooltip
} from "@mui/material";
import {
  Delete as DeleteIcon,
  AddCircleOutline as AddCircleOutlineIcon
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)<{ pb?: number }>`
  &:last-child {
    padding-bottom: 20px;
  }
`;

const TaskWrapper = styled(Card)`
  border: 1px solid black;
  margin-bottom: 20px;
  cursor: grab;

  &:hover {
    background: white;
  }
`;

const TaskWrapperContent = styled(CardContent)`
  position: relative;

  &:last-child {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Typography = styled(MuiTypography)(spacing);

const onDragEnd = (result: DropResult, columns: any, setColumns: any, setUpdateUI: any) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
		const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setUpdateUI(true);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
		const column = columns[source.droppableId];
		const copiedItems = [...column.items];
		const [removed] = copiedItems.splice(source.index, 1);
		copiedItems.splice(destination.index, 0, removed);
		copiedItems.forEach((item, index) => {
			item.order = index;
    })
    setUpdateUI(true);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};

interface LaneProps {
  column: {
    title: string;
    description: string;
  };
  children: ReactNode;
}

const Lane = ({ column, children }: LaneProps) => {
  return (
    <Grid item xs={12} lg={4} xl={4}>
      <Card mb={6}>
        <CardContent pb={0}>
          <Typography variant="h6" gutterBottom>
            {column.title}
          </Typography>
          <Typography variant="body2" mb={4}>
            {column.description}
          </Typography>
          {children}
        </CardContent>
      </Card>
    </Grid>
  );
};

interface TaskProps {
  item: {
    title: string;
  };
}

const Task = ({ item, columns, setColumns, setUpdateUI }: TaskProps) => {

  const handleSave = ({ name, value, previousValue }) => {
    item.title = value;

    const updatedTabs = { ...columns };
    for (let key in updatedTabs) {
      const chartIndex = updatedTabs[key].items.findIndex(chart => chart.id === item.id);
      if (chartIndex !== -1) {
        updatedTabs[key].items[chartIndex] = { ...updatedTabs[key].items[chartIndex], ...item };
      }
      break;
    }
    setUpdateUI(true);
    setColumns(updatedTabs);
  };

  const onClickDelete = () => {
    const updatedColumns = { ...columns };
    updatedColumns[0].items = columns[0].items.filter(tabItem => tabItem.id !== item.id);
    updatedColumns[0].items.map((tabItem, index) => {
      tabItem.order = index; 
    })

    setUpdateUI(true);
    setColumns(updatedColumns);
  }

  return (
    <TaskWrapper mb={4}>
      <TaskWrapperContent>
        <EditText
          defaultValue={item.title}
          editButtonProps={{ style: { marginLeft: '5px', paddingBottom: '5px', width: 16 } }}
          style={{
            fontSize: '15px',
            fontWeight: 600,
            marginRight: '10px'
          }}
          showEditButton
          onSave={handleSave}
        />   
        <div>
          <Tooltip title="Delete">
            <IconButton onClick={onClickDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      </TaskWrapperContent>
      
    </TaskWrapper>
  );
};

interface TabsCmpProps {
  token: string
}

export function TabsCmp({
	token,
	tenantId
}: any): JSX.Element {
	const [documentReady, setDocumentReady] = useState(false);
	const [columns, setColumns] = useState({});
  const [tabs, setTabs] = useState<Tabs | null>(null);
  const [updateUI, setUpdateUI] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [newTabName, setNewTabName] = useState("");

	useEffect((): void => {
    if (!token) return;
    const callTabs = async (): Promise<any> => {
			const tabs: Tabs | null = await getChartsByTenantId(token, tenantId);
      if (tabs) {
				setColumns({
					["0"]: {
						title: "Tab List",
						description: "You can drag and drop tab items",
						items: tabs.tabs
					}
				})
			}
			setDocumentReady(true);
			setTabs(tabs);
    }

    callTabs();
	}, [token]);

  useEffect((): void => {
		if (columns && columns[0] && columns[0].items && updateUI)
    {
			const tmpTabs = tabs;
			tmpTabs.tabs = columns[0].items;
			const callUpdateTabs = async (): Promise<any> => {
				const result = await updateCharts(token, tenantId, tmpTabs);
				if (result) {
					setTabs(tmpTabs);
				}
			}
			callUpdateTabs(tmpTabs);	
		}
	},[columns])

  const onClickAdd = () => {
    const newTabId = newTabName.toLowerCase().replace(/ /g, "-") + "-tab";
    const updatedColumns = { ...columns };
    updatedColumns[0].items.push(
      {
        id: newTabId,
        order: updatedColumns[0].items.length,
        title: newTabName,
        charts: []
      }
    )
    setUpdateUI(true);
    setColumns(updatedColumns);
    onCloseModal();
  }

  const onCloseModal = () => {
    setOpenModal(false);
    setNewTabName('');
  }

	return (
		<React.Fragment>
      <Grid container spacing={6}>
        {!!documentReady && (
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, columns, setColumns, setUpdateUI)}
          >
						{Object.entries(columns).map(([columnId, column]) => (
              <Lane key={columnId} column={column}>
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          minHeight: 50,
                        }}
											>
                        {column.items.map((item: { id?: any; title?: string; }, index: number) => {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Task item={item} columns={columns} setColumns={ setColumns } setUpdateUI={ setUpdateUI } />
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
                <div style={{ textAlign: 'center' }}>
                  <Tooltip title="Add new tab">
                    <IconButton onClick={() => setOpenModal(true)}>
                      <AddCircleOutlineIcon sx={{ cursor: 'pointer', fontSize: '30px' }} />
                    </IconButton>
                  </Tooltip>
                </div>
              </Lane>
            ))}
          </DragDropContext>
        )}
      </Grid>
      <Dialog
        open={openModal}
        onClose={onCloseModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add new tab</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            value={newTabName}
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewTabName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={onClickAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
	);
}

interface DispatchFromProps {
  
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  
})

const mapStateToProps = (state: State): TabsCmpProps => ({
  token: state.userState.token || '',
})

export const TabsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(TabsCmp)