import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Dispatch, State } from "../reducers";
import { TenantState } from "../reducers/tenant";
import { User } from "../apis/api";

interface UserAddModalPRops {
  value: boolean;
  onClick: Function;
  userList: User[];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface TenantsProps {
  tenants: TenantState[];
}

const mapStateToProps = (state: State): TenantsProps => ({
  tenants: state.tenantState,
});

export function UserAddModal({
  value,
  onClick,
  userList,
}: UserAddModalPRops): JSX.Element {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedTenants, setSelectedTenants] = useState<string[]>([]);
  const [selectedTenantIds, setSelectedTenantIds] = useState<string[]>([]);
  const { tenants } = useSelector(mapStateToProps);
  const [userId, setUserId] = useState<string>("");

  const handleChange = (event: SelectChangeEvent<typeof selectedTenants>) => {
    const {
      target: { value },
    } = event;
    var ids: string[] = [];
    if (value.length == 0) {
      setSelectedTenantIds([]);
    } else if (typeof value != "string") {
      value.map((item: string) => {
        const result = tenants.filter((tenant) => tenant.display == item);
        if (result.length > 0) {
          ids.push(result[0].id);
        }
      });
      setSelectedTenantIds(ids);
    }

    setSelectedTenants(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleUpdateModal = (flag: boolean) => {
    if (flag) {
      onClick(flag, email, password, selectedTenantIds, userId);
    } else {
      onClick(flag, null, null, [], null);
    }
    setEmail("");
    setPassword("");
    setSelectedTenants([]);
    setUserId("");
  };

  const options = userList.map((item) => ({
    id: item.user_id,
    label: item.nickname,
  }));

  return (
    <Dialog
      open={value}
      onClose={() => handleUpdateModal(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">New User</DialogTitle>
      <DialogContent>
        <Autocomplete
          id="combo-box-demo"
          options={options}
          sx={{ mt: "10px", mb: "15px" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a user"
              InputLabelProps={{ shrink: true }}
            />
          )}
          value={
            userId
              ? options?.find((option) => {
                  return userId === option.id;
                }) ?? null
              : null
          }
          onChange={(event: any, newValue) => {
            if (newValue) {
              const [matchedUser] = userList.filter(
                (item) => item.user_id === newValue.id
              );

              if (
                matchedUser?.user_metadata?.tenantIds &&
                matchedUser.user_metadata.tenantIds.length > 0
              ) {
                const tmpSelectedTenants: string[] = [];

                matchedUser?.user_metadata?.tenantIds.forEach((item1) => {
                  const tenant = tenants.find((idx) => idx.id === item1);
                  if (tenant) {
                    tmpSelectedTenants.push(tenant.display);
                  }
                });

                setSelectedTenants(tmpSelectedTenants);
              }
            } else {
              setSelectedTenants([]);
            }
            setUserId(newValue ? newValue.id : "");
          }}
        />
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="Email"
          type="email"
          value={email}
          fullWidth
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(event.target.value)
          }
          InputLabelProps={{ shrink: true }}
          sx={{ mb: "15px" }}
          disabled={userId ? true : false}
        />
        <TextField
          margin="dense"
          id="password"
          label="Password"
          type="password"
          value={password}
          fullWidth
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(event.target.value)
          }
          InputLabelProps={{ shrink: true }}
          disabled={userId ? true : false}
        />
        <FormControl sx={{ marginTop: "20px", width: 552 }}>
          <InputLabel id="demo-multiple-checkbox-label">Tenants</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={selectedTenants}
            onChange={handleChange}
            input={<OutlinedInput label="Tenants" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {tenants.map((tenant) => (
              <MenuItem key={tenant.id} value={tenant.display}>
                <Checkbox
                  checked={selectedTenants.indexOf(tenant.display) > -1}
                />
                <ListItemText primary={tenant.display} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleUpdateModal(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleUpdateModal(true)} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
