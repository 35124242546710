import { User } from "../apis/api"

export const SET_USERS = 'SET_USERS'
export interface SetUsers {
	type: typeof SET_USERS
	payload: User[]
}

export const setUsers = (payload: User[]): SetUsers => ({
	type: SET_USERS,
	payload
})

export const ADD_THE_USER = 'ADD_THE_USER'
export interface AddTheUser {
	type: typeof ADD_THE_USER
	payload: User
}

export const addTheUser = (payload: User): AddTheUser => ({
	type: ADD_THE_USER,
	payload
})

export const UPDATE_THE_USER = 'UPDATE_THE_USER'
export interface UpdateTheUser {
	type: typeof UPDATE_THE_USER
	payload: User
}

export const updateTheUser = (payload: User): UpdateTheUser => ({
	type: UPDATE_THE_USER,
	payload
})

export const usersReducer = (
	state: User[] = [],
	action: SetUsers | AddTheUser | UpdateTheUser
): User[] => {

	switch (action.type) {
		case SET_USERS:
			return action.payload
		case ADD_THE_USER:
			return [...state, action.payload]
		case UPDATE_THE_USER:
			return state.map(item =>
				item.user_id === action.payload.user_id
					? { ...item, ...action.payload }
					: item);
		default:
			return state
	}
}