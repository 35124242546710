import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Users } from './Users';
import { useParams } from 'react-router-dom';
import { Charts } from './Charts';
import { TabsComponent } from './Tabs';
import { AppBarHeader } from './Appbar';
import { Dispatch, State } from '../reducers';
import { connect, useSelector } from 'react-redux'
import { setTenants as setTenantsReducer, TenantSet, TenantState, addTenant as addTenantReducer, updateTenant as updateTenantReducer } from '../reducers/tenant';
import { addTenant, getTenants, Tenant, updateTenant, addChart } from "../apis/api";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface RouteParams {
  id: string;
}

interface TenantDetailProps {
  token: string
  tenants: TenantState[]
}

const TenantDetailComponent = ({
  token,
  tenants,
  setTenantsReducer,
}: any ): JSX.Element => {
  const [value, setValue] = React.useState(0);
  const [name, setName] = React.useState('');
  const { id } = useParams<RouteParams>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  
  useEffect((): void => {
    if (!token) return;

    const callTenants = async (): Promise<any> => {
      const tenants: Tenant[] | null = await getTenants(token);
      setTenantsReducer(tenants ? tenants : []);
    }
    callTenants();
  }, [token]);
	
  useEffect((): void => {
    const result = tenants.find((tenant: any) => tenant.id == id);
    if (result) {
      setName(result.display);
    }
  }, [tenants]);

	return (
		<Box
      sx={{ bgcolor: 'background.paper' }}
    >
      <AppBarHeader />
      <Alert severity="info">Editing tenant: { name }</Alert>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label="Users" {...a11yProps(0)} />
        <Tab label="Tabs" {...a11yProps(1)} />
        <Tab label="Charts" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
				<Users tenantId={ id } />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TabsComponent tenantId={ id } />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Charts tenantId={ id } />
      </TabPanel>
    </Box>
	);
}

interface DispatchFromProps {
  setTenantsReducer: (tenants: TenantState[]) => void
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  setTenantsReducer: (tenants): void => {
    dispatch(setTenantsReducer(tenants))
  }
})

const mapStateToProps = (state: State): TenantDetailProps => ({
  token: state.userState.token || '',
  tenants: state.tenantState
})

export const TenantDetail = connect(
  mapStateToProps,
  mapDispatchToProps
)(TenantDetailComponent)