import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  Checkbox,
  Divider as MuiDivider,
  IconButton,
  Paper as MuiPaper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  Update as UpdateIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";

import { addUser, getUsers, User, updateUser } from "../apis/api";

import { spacing } from "@mui/system";
import { Dispatch, State } from "../reducers";
import { UserAddModal } from "../modals/UserAddModal";
import {
  setUsers as setUsersReducer,
  addTheUser as addTheUserReducer,
  updateTheUser as updateTheUserReducer,
} from "../reducers/users";
import { UserUpdateModal } from "../modals/UserUpdateModal";

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const UserName = styled.div`
  display: flex;
  align-items: center;
`;

function descendingComparator(a: User, b: User, orderBy: string) {
  // @ts-ignore
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  // @ts-ignore
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: "desc" | "asc", orderBy: string) {
  return order === "desc"
    ? (a: User, b: User) => descendingComparator(a, b, orderBy)
    : (a: User, b: User) => -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: Array<User>,
  comparator: (a: User, b: User) => number
) {
  const stabilizedThis = array.map((el: User, index: number) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

type HeadCell = {
  id: string;
  alignment: "left" | "center" | "right" | "justify" | "inherit" | undefined;
  label: string;
  disablePadding?: boolean;
};
const headCells: Array<HeadCell> = [
  { id: "nickname", alignment: "left", label: "User" },
  { id: "userId", alignment: "left", label: "User ID" },
  { id: "createdAt", alignment: "left", label: "Created Date" },
  { id: "actions", alignment: "right", label: "Actions" },
];

type EnhancedTableHeadProps = {
  order: "desc" | "asc";
  orderBy: string;
  rowCount: number;
  onRequestSort: (e: any, property: string) => void;
};
const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

type EnhancedTableToolbarProps = {};
const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  return (
    <Toolbar>
      <ToolbarTitle>
        <Typography variant="h6" id="tableTitle">
          Users
        </Typography>
      </ToolbarTitle>
      <Spacer />
      <Tooltip title="Filter list">
        <IconButton aria-label="Filter list" size="large">
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

interface EnhancedTablePRops {
  users: User[];
  setUpdateModal: Function;
}

function EnhancedTable({
  users,
  setUpdateModal,
}: EnhancedTablePRops): JSX.Element {
  const [order, setOrder] = React.useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = React.useState("nickname");
  const [selected, setSelected] = React.useState<Array<string>>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={users.length}
            />
            <TableBody>
              {stableSort(users, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.user_id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.user_id}-${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell component="th" id={labelId} scope="row">
                        <UserName>
                          <Box>{row.nickname}</Box>
                        </UserName>
                      </TableCell>
                      <TableCell align="left">{row.user_id}</TableCell>
                      <TableCell>{row.created_at}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="delete"
                          size="large"
                          onClick={() => setUpdateModal(true, row.user_id)}
                        >
                          <UpdateIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                    {emptyRows == rowsPerPage ? "No user" : ""}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

interface UsersProps {
  token: string;
  users: User[];
}

export function UsersComponent({
  token,
  tenantId,
  users,
  setUsersReducer,
  addTheUserReducer,
  updateTheUserReducer,
}: any): JSX.Element {
  const [openModal, setOpenModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [updateUserId, setUpdateUserId] = useState("");
  const [updateTenantIds, setUpdateTenantsIds] = useState([]);
  const [userList, setUserList] = useState<User[]>([]);

  const filterByTenantId = (users: User[], tenantId: string): User[] => {
    return users.filter((user) => {
      const tenantIds = user.user_metadata?.tenantIds;
      return tenantIds ? tenantIds.includes(tenantId) : false;
    });
  };

  useEffect((): void => {
    if (!token) return;
    const callUsers = async (): Promise<any> => {
      const users: User[] | null = await getUsers(token);
      const filteredUsers = users ? filterByTenantId(users, tenantId) : [];
      const missingItems = users?.filter(
        (loadedItem) =>
          !filteredUsers.some((item) => item.user_id === loadedItem.user_id)
      );
      setUserList(missingItems ?? []);
      setUsersReducer(filteredUsers);
    };

    callUsers();
  }, [token]);

  useEffect((): void => {
    if (!token) return;
    const callUsers = async (): Promise<any> => {
      const users: User[] | null = await getUsers(token);
      setUsersReducer(users ? filterByTenantId(users, tenantId) : []);
    };

    callUsers();
  }, []);

  const handleAddChange = async (
    val: boolean,
    email: string,
    password: string,
    tenantIds: string[],
    userId: string
  ) => {
    setOpenModal(false);
    if ((email || userId) && tenantIds && val) {
      if (userId) {
        const user: User | null = await updateUser(token, {
          id: userId,
          tenantIds,
        });
        if (user) {
          addTheUserReducer(user);
        }
      } else {
        const user: User | null = await addUser(token, {
          email,
          password,
          tenantIds,
        });
        if (user) {
          addTheUserReducer(user);
        }
      }
    }
  };

  const handleUpdateChange = async (val: boolean, tenantIds: string[]) => {
    setOpenUpdateModal(false);
    setUpdateTenantsIds([]);

    if (val) {
      const result = await updateUser(token, { id: updateUserId, tenantIds });

      if (result) {
        const result1 = users.filter(
          (user: { user_id: string }) => user.user_id == updateUserId
        );
        if (result1 && result1.length > 0) {
          result1[0].user_metadata.tenantIds = tenantIds;
          updateTheUserReducer(result1[0]);
        }
      }
    }
  };

  useEffect((): void => {
    if (updateTenantIds && updateTenantIds.length > 0) {
      setOpenUpdateModal(true);
    }
  }, [updateTenantIds]);

  const handleUpdateModal = (val: boolean, userId: string) => {
    const result = users.filter(
      (user: { user_id: string }) => user.user_id == userId
    );
    if (result && result.length > 0) {
      setUpdateUserId(userId);
      setUpdateTenantsIds(result[0].user_metadata.tenantIds);
    }
  };

  return (
    <React.Fragment>
      <Grid
        justifyContent="end"
        container
        spacing={10}
        sx={{ marginBottom: "30px" }}
      >
        <Grid item>
          <div>
            <Button
              variant="contained"
              sx={{ backgroundColor: "rgb(0, 131, 143)" }}
              onClick={() => setOpenModal(true)}
            >
              <AddIcon />
              New User
            </Button>
          </div>
        </Grid>
      </Grid>

      <UserAddModal
        value={openModal}
        onClick={(
          val: boolean,
          email: string,
          password: string,
          tenantId: string[],
          userId: string
        ) => handleAddChange(val, email, password, tenantId, userId)}
        userList={userList}
      />

      <UserUpdateModal
        value={openUpdateModal}
        tenantIdProp={updateTenantIds}
        onClick={(val: boolean, tenantIds: string[]) =>
          handleUpdateChange(val, tenantIds)
        }
      />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable
            users={filterByTenantId(users, tenantId)}
            setUpdateModal={(val: boolean, userId: string) =>
              handleUpdateModal(val, userId)
            }
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

interface DispatchFromProps {
  setUsersReducer: (users: User[]) => void;
  addTheUserReducer: (user: User) => void;
  updateTheUserReducer: (user: User) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  setUsersReducer: (users): void => {
    dispatch(setUsersReducer(users));
  },
  addTheUserReducer: (user): void => {
    dispatch(addTheUserReducer(user));
  },
  updateTheUserReducer: (user): void => {
    dispatch(updateTheUserReducer(user));
  },
});

const mapStateToProps = (state: State): UsersProps => ({
  token: state.userState.token || "",
  users: state.usersState,
});

export const Users = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersComponent);
