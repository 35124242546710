import axios from "./axiosInstance";

export interface TenantId {
  tenantIds: string[];
}

export interface Tenant {
  id: string;
  name: string;
  display: string;
  tenantTypeId: string;
}

export interface TenantType {
  id: string;
  name: string;
}

export interface User {
  user_id: string;
  nickname: string;
  user_metadata?: TenantId;
  created_at: string;
}

export interface Chart {
  id: string;
  title: string;
  order: number;
  hidden: boolean;
}

export interface Tab {
  id: string;
  order: number;
  charts: Chart[];
}

export interface Tabs {
  id: string;
  tenant_id: string;
  tabs: Tab[];
}

export const getUsers = async (token: string): Promise<User[] | null> => {
  try {
    const response = await axios.get("/api/v1/users", {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data ? response.data.data : null;
  } catch (error) {
    console.warn("request error");
    return null;
  }
};

export const addUser = async (
  token: string,
  user: {
    email: string;
    password: string;
    tenantIds: string[];
  }
): Promise<User | null> => {
  try {
    const response = await axios.post("/api/v1/users", user, {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data ? response.data.items : null;
  } catch (error) {
    console.warn("request error");
    return null;
  }
};

export const updateUser = async (
  token: string,
  user: {
    id: string;
    tenantIds: string[];
  }
): Promise<User | null> => {
  try {
    const response = await axios.put(`/api/v1/users`, user, {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data ? response?.data?.data : null;
  } catch (error) {
    console.warn("request error");
    return null;
  }
};

export const getTenants = async (token: string): Promise<Tenant[] | null> => {
  try {
    const response = await axios.get("/api/v1/tenants", {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data ? response.data.items : null;
  } catch (error) {
    console.warn("request error");
    return null;
  }
};

export const addTenant = async (
  token: string,
  tenant: {
    name: string;
    display: string;
    tenantTypeId: string;
    baseTenantId: string;
    image: File;
  }
): Promise<Tenant | null> => {
  try {
    const formData = new FormData();
    formData.append("name", tenant.name);
    formData.append("display", tenant.display);
    formData.append("tenantTypeId", tenant.tenantTypeId);
    formData.append("baseTenantId", tenant.baseTenantId);
    formData.append("image", tenant.image, tenant.image.name);

    const response = await axios.post("/api/v1/tenants", formData, {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data ? response.data : null;
  } catch (error) {
    console.warn("request error");
    return null;
  }
};

export const updateTenant = async (
  token: string,
  id: string,
  tenant: {
    id: string;
    name: string;
    display: string;
    tenantTypeId: string;
    image: File;
  }
): Promise<boolean> => {
  const formData = new FormData();
  formData.append("name", tenant.name);
  formData.append("display", tenant.display);
  formData.append("tenantTypeId", tenant.tenantTypeId);
  formData.append("image", tenant.image, tenant.image.name);

  try {
    await axios.put(`/api/v1/tenants/${id}`, formData, {
      headers: { Authorization: "Bearer " + token },
    });
    return true;
  } catch (error) {
    console.warn("request error");
    return false;
  }
};

export const deleteTenant = async (
  token: string,
  id: string,
): Promise<boolean> => {
  try {
    await axios.delete(`/api/v1/tenants/${id}`, {
      headers: { Authorization: "Bearer " + token },
    });
    return true;
  } catch (error) {
    console.warn("request error");
    return false;
  }
};

export const addChart = async (
  token: string,
  tabs: any
): Promise<Tabs | null> => {
  try {
    const response = await axios.post("/api/v1/charts", tabs, {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data ? response.data : null;
  } catch (error) {
    console.warn("request error");
    return null;
  }
};

export const getChartsByTenantId = async (
  token: string,
  id: string
): Promise<Tabs | null> => {
  try {
    const response = await axios.get(`/api/v1/charts/${id}`, {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data ? response.data : null;
  } catch (error) {
    console.warn("request error");
    return null;
  }
};

export const updateCharts = async (
  token: string,
  id: string,
  tabs: any
): Promise<boolean> => {
  try {
    await axios.put(`/api/v1/charts/${id}`, tabs, {
      headers: { Authorization: "Bearer " + token },
    });
    return true;
  } catch (error) {
    console.warn("request error");
    return false;
  }
};

export const getTenantTypes = async (
  token: string
): Promise<Tenant[] | null> => {
  try {
    const response = await axios.get("/api/v1/tenant/types", {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data ? response.data.items : null;
  } catch (error) {
    console.warn("request error");
    return null;
  }
};
