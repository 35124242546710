// @ts-nocheck - may need to be at the start of file
import React, { useState, useEffect, useContext } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'

const DEFAULT_REDIRECT_CALLBACK = (): void =>
  window.history.replaceState(null, document.title, window.location.pathname)

interface AppContextInterface {
  isAuthenticated?: boolean
  loginWithRedirect: Function
  logout: Function
  user?: {}
  loading?: boolean
  popupOpen?: boolean
  loginWithPopup?: Function
  handleRedirectCallback?: Function
  getIdTokenClaims?: Function
  getTokenSilently?: Function
  getTokenWithPopup?: Function
}

interface Auth0Provider extends Auth0ClientOptions {
  children: JSX.Element
  onRedirectCallback: Function
  user?: {}
  grant_type?: string
}

export const Auth0Context = React.createContext<AppContextInterface>({
  loginWithRedirect: (): void => {},
  logout: (): void => {}
})
export const useAuth0 = (): AppContextInterface => useContext(Auth0Context)
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: Auth0Provider): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect((): void => {
    const initAuth0 = async (): Promise<void> => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()
        setUser(user)
      }

      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  const loginWithPopup = async (params = {}): Promise<void> => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const user = await auth0Client.getUser()
    setUser(user)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async (): Promise<void> => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const user = await auth0Client.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
  }
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p: unknown[]): void =>
          auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p: unknown[]): void =>
          auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p: unknown[]): void =>
          auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p: unknown[]): void =>
          auth0Client.getTokenWithPopup(...p),
        logout: (): void => auth0Client.logout({returnTo: window.location.origin})
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
