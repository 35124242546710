import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useAuth0 } from '../auth0_wrapper';
import { useHistory } from 'react-router-dom';
import {
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export const AppBarHeader =  function AppBarHeader(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
	const { logout } = useAuth0();
	const history = useHistory();
	
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

	const goMainPage = () => {
		history.push(`/`);
	}

  return (
    <AppBar
      position='relative'
    >
      <Toolbar sx={{ backgroundColor: 'rgb(0, 131, 143)'}}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
        <MenuIcon />
      </IconButton>
      {
        history.location.pathname.includes("tenants") ? (
          <ArrowBackIcon onClick={goMainPage} sx={{ cursor: 'pointer', marginRight: '10px' }} />
        ): null
      }
      <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, cursor: 'pointer' }} onClick={() => goMainPage() }>
        Dashboard
      </Typography>
      <Button color="inherit" onClick={(): void => { logout() }}>Logout</Button>
    </Toolbar>
  </AppBar>
  );
}
