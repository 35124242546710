// @ts-nocheck - may need to be at the start of file
import React, { useState, useEffect, ReactNode } from "react";
import styled from "@emotion/styled";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { connect } from 'react-redux'
import { EditText } from 'react-edit-text';
import { Dispatch, State } from '../reducers';
import { Tabs, getChartsByTenantId, updateCharts } from "../apis/api";
import {
  Visibility as ShowIcon,
  VisibilityOff as HideIcon,
} from "@mui/icons-material";

import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Grid,
  Typography as MuiTypography,
  IconButton,
  Tooltip
} from "@mui/material";
import { spacing } from "@mui/system";
import defaultCorpChartList from '../corp_chart_list.json';
import defaultBankChartList from '../bank_chart_list.json';
import 'react-edit-text/dist/index.css';

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)<{ pb?: number }>`
  &:last-child {
    padding-bottom: 20px;
  }
`;

const TaskWrapper = styled(Card)`
  border: 1px solid black;
  margin-bottom: 20px;
  cursor: grab;

  &:hover {
    background: white;
  }
`;

const TaskWrapperContent = styled(CardContent)`
  position: relative;

  &:last-child {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Typography = styled(MuiTypography)(spacing);

const TaskTitle = styled(Typography)`
  font-weight: 600;
  font-size: 15px;
  margin-right: 10px;
`;

const onDragEnd = (result: DropResult, columns: any, setColumns: any, setUpdateUI: any) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.charts];
    const destItems = [...destColumn.charts];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setUpdateUI(true);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        charts: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        charts: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.charts];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setUpdateUI(true);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        charts: copiedItems,
      },
    });
  }
};

interface LaneProps {
  column: {
    title: string;
    description: string;
  };
  children: ReactNode;
}

const Lane = ({ column, children }: LaneProps) => {
  return (
    <Grid item xs={12} lg={12} xl={12}>
      <Card mb={6}>
        <CardContent pb={0}>
          <Typography variant="h6" gutterBottom>
            {column.title}
          </Typography>
          {/* <Typography variant="body2" mb={4}>
            {column.description}
          </Typography> */}
          {children}
        </CardContent>
      </Card>
    </Grid>
  );
};

const Task = ({ item, columns, setColumns, setUpdateUI }: any) => {

  const onClickFunc = (value: boolean) => {
    item.hidden = value;

    const updatedTabs = { ...columns };
    for (let key in updatedTabs) {
      const chartIndex = updatedTabs[key].charts.findIndex(chart => chart.id === item.id);
      if (chartIndex !== -1) {
        updatedTabs[key].charts[chartIndex] = { ...updatedTabs[key].charts[chartIndex], ...item };
      }
      break;
    }
    setUpdateUI(true);
    setColumns(updatedTabs);
  }

  const handleSave = ({ name, value, previousValue }) => {
    item.title = value;

    const updatedTabs = { ...columns };
    for (let key in updatedTabs) {
      const chartIndex = updatedTabs[key].charts.findIndex(chart => chart.id === item.id);
      if (chartIndex !== -1) {
        updatedTabs[key].charts[chartIndex] = { ...updatedTabs[key].charts[chartIndex], ...item };
      }
      break;
    }
    setUpdateUI(true);
    setColumns(updatedTabs);

  };

  return (
    <TaskWrapper mb={4} sx={{ opacity: item.hidden ? 0.1 : 1}}>
      <TaskWrapperContent>
        {item.hidden ? (
          <TaskTitle variant="body1">
            {item.title}
          </TaskTitle>
        ) : (
          <EditText
            defaultValue={item.title}
            editButtonProps={{ style: { marginLeft: '5px', paddingBottom: '5px', width: 16 } }}
            style={{
              fontSize: '15px',
              fontWeight: 600,
              marginRight: '10px'
            }}
            showEditButton
            onSave={handleSave}
          />    
        )}
        <div>
          {item.hidden ? (
            <Tooltip title="Show">
              <IconButton onClick={() => onClickFunc(false)}>
                <ShowIcon />
              </IconButton>
            </Tooltip>
          ): (
            <Tooltip title="Hide">
              <IconButton onClick={() => onClickFunc(true)}>
                <HideIcon />
              </IconButton>
            </Tooltip>    
          )}
        </div>
      </TaskWrapperContent>
    </TaskWrapper>
  );
};

interface ChartsCmpProps {
  token: string
}

export function ChartsCmp({
  token,
  tenantId
}: any): JSX.Element {
  const [columns, setColumns] = useState({});
  const [documentReady, setDocumentReady] = useState(false);
  const [tabs, setTabs] = useState<Tabs | null>(null);
  const [updateUI, setUpdateUI] = useState(false);

  useEffect((): void => {
    if (!token) return;
    const callTabs = async (): Promise<any> => {
      const tabs: Tabs | null = await getChartsByTenantId(token, tenantId);
      let updatedCharts = defaultCorpChartList.charts.filter(chartItem => 
        !tabs?.tabs.some(tab => 
          tab.charts.some(chart => chartItem.id === chart.id)
        )
      );

      tabs.tabs.unshift({
        id: "chart-list",
        title: "Chart List",
        order: -1,
        charts: updatedCharts
      });
        
      if (tabs) {
        const convertedData: { [key: string]: Tab } = tabs.tabs.reduce((acc, tab, index) => {
          acc[index.toString()] = tab;
          return acc;
        }, {});
        setColumns(convertedData);
        setTabs(tabs);
        setDocumentReady(true);
      }
    }

    callTabs();
  }, [token]);

  useEffect((): void => {
    if (columns && columns[0] && columns[0].charts && updateUI) {
      const tmpTabs = tabs;
      const originalData: Tab[] = Object.values(columns);
      originalData.shift();
      originalData.forEach(tab => {
        tab.charts.forEach((item, index) => {
          item.order = index;
        })
      })

      tmpTabs.tabs = originalData;
      const callUpdateTabs = async (): Promise<any> => {
        const result = await updateCharts(token, tenantId, tmpTabs);
        if (result) {
          setTabs(tmpTabs);
        }
      }
      callUpdateTabs(tmpTabs);
    }
  }, [columns]);

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        {!!documentReady && (
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, columns, setColumns, setUpdateUI)}
          >
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              {Object.entries(columns).map(([columnId, column]) => {
                if(columnId == 0)
                  return (
                    <Lane key={columnId} column={column}>
                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                minHeight: 50,
                              }}
                            >
                              {column.charts.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                  >
                                    {(provided) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <Task item={item} columns={columns} setColumns={setColumns} setUpdateUI={setUpdateUI} />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </Lane>
                  )
              })}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              {Object.entries(columns).map(([columnId, column]) => {

                if(columnId != 0)
                  return (
                    <Lane key={columnId} column={column}>
                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                minHeight: 50,
                              }}
                            >
                              {column.charts.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                  >
                                    {(provided) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <Task item={item} columns={columns} setColumns={setColumns} setUpdateUI={setUpdateUI} />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </Lane>
                  )
              })}
            </Grid>
            
          </DragDropContext>
        )}
      </Grid>
    </React.Fragment>
  );
}

interface DispatchFromProps {
  
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  
})

const mapStateToProps = (state: State): ChartsCmpProps => ({
  token: state.userState.token || '',
})

export const Charts = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChartsCmp)