import { useState, ChangeEvent } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";

import Select, { SelectChangeEvent } from "@mui/material/Select";

import { TenantState } from "../reducers/tenant";

import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import { TenantType } from "../apis/api";

interface TenantAddModalPRops {
  value: boolean;
  onClick: Function;
  tenants: TenantState[];
  types: TenantType[];
}
export function TenantAddModal({
  value,
  onClick,
  tenants,
  types,
}: TenantAddModalPRops): JSX.Element {
  const [name, setName] = useState("");
  const [display, setDisplay] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [baseTenantId, setBaseTenantId] = useState<string>("");
  const [tenantTypeId, setTenantTypeId] = useState<string>("");

  const handleChange = (event: SelectChangeEvent) => {
    setBaseTenantId(event.target.value as string);
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setTenantTypeId(event.target.value as string);
  };

  const handleUpdateModal = (flag: boolean) => {
    if (flag) {
      onClick(flag, name, display, tenantTypeId, selectedFile, baseTenantId);
    } else {
      onClick(flag, null, null, null, null, null);
    }
    setName("");
    setDisplay("");
    setSelectedFile(null);
    setPreviewImage(null);
    setBaseTenantId("");
    setTenantTypeId("");
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
      setPreviewImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  return (
    <Dialog
      open={value}
      onClose={() => handleUpdateModal(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">New Tenant</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          value={name}
          fullWidth
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setName(event.target.value)
          }
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          autoFocus
          margin="dense"
          id="display"
          label="Display"
          type="text"
          value={display}
          fullWidth
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setDisplay(event.target.value)
          }
          InputLabelProps={{ shrink: true }}
        />
        <FormControl fullWidth sx={{ mb: "10px", mt: "10px" }}>
          <InputLabel id="demo-simple-select-label">
            Copy existing Tenant
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={baseTenantId}
            label="Tenant"
            onChange={handleChange}
          >
            {tenants.map((tenant, index) => {
              return (
                <MenuItem value={tenant.id} key={index}>
                  {tenant.display}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tenantTypeId}
            label="Type"
            onChange={handleTypeChange}
          >
            {types.map((type, index) => {
              return (
                <MenuItem value={type.id} key={index}>
                  {type.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "10px",
            padding: "10px",
            border: "1px solid rgba(0,0,0,0.2)",
            borderRadius: "6px",
          }}
        >
          {previewImage && (
            <img
              src={previewImage}
              alt=""
              style={{ width: "100px", marginBottom: "10px" }}
            />
          )}
          <input
            type="file"
            id="contained-button-file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              sx={{ textTransform: "capitalize" }}
            >
              <CloudUploadIcon />
              &nbsp;Upload Logo
            </Button>
          </label>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleUpdateModal(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => handleUpdateModal(true)}
          color="primary"
          disabled={
            name && display && baseTenantId && tenantTypeId && previewImage
              ? false
              : true
          }
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
