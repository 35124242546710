export interface UserState {
  email: string | null
  name: string | null
  nickname: string | null
  picture: string | null
  token: string | null
}

export interface UserSet {
  email?: string | null
  name?: string | null
  nickname?: string | null
  picture?: string | null
  token?: string | null
}

export const SET_USER = 'SET_USER'
export interface SetUser {
  type: typeof SET_USER
  payload: UserSet
}

export const setUser = (payload: UserSet): SetUser => ({
  type: SET_USER,
  payload
})

export const userReducer = (
  state: UserState = {
    email: null,
    name: null,
    nickname: null,
    picture: null,
    token: null,
  },
  action: SetUser
): UserState => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
