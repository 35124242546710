import { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import { TenantState } from "../reducers/tenant";
import { State } from "../reducers";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface TenantsProps {
  tenants: TenantState[]
}

const mapStateToProps = (state: State): TenantsProps => ({
  tenants: state.tenantState
})

interface UserUpdateModalPRops {
	value: boolean
	tenantIdProp: string[]
	onClick: Function
}
export function UserUpdateModal({
	value,
	tenantIdProp,
	onClick
}: UserUpdateModalPRops): JSX.Element {
	const [selectedTenants, setSelectedTenants] = useState<string[]>([]);
	const [selectedTenantIds, setSelectedTenantIds] = useState<string[]>([]);
	const { tenants } = useSelector(mapStateToProps);
	
	useEffect((): void => {
		if (tenantIdProp && tenantIdProp.length > 0) {
			var selectedTenants: string[] = [];
			tenantIdProp.map(tenantId => {
				const result = tenants.filter(tenant => tenant.id == tenantId)
				if (result && result.length > 0)
				{
					selectedTenants.push(result[0].display);
				}
			})
			setSelectedTenants(selectedTenants);
			setSelectedTenantIds(tenantIdProp);
		}
	}, [tenantIdProp]);

	const handleChange = (event: SelectChangeEvent<typeof selectedTenants>) => {
    const {
      target: { value },
		} = event;
		var ids:string[] = []
		if (value.length == 0) {
			setSelectedTenantIds([]);
		} else if (typeof value != 'string') {
			value.map((item: string) => {
				const result = tenants.filter(tenant => tenant.display == item)
				if (result.length > 0) {
					ids.push(result[0].id);
				}
			})
			setSelectedTenantIds(ids);
		}
		
    setSelectedTenants(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
	};
	
	const handleUpdateModal = (flag: boolean) => {
		if (flag) {
			onClick(flag, selectedTenantIds);
		} else {
			onClick(flag, []);
		}
		setSelectedTenants([]);
	}

	return (
		<Dialog
			open={value}
			onClose={() => handleUpdateModal(false)}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">Update User</DialogTitle>
			<DialogContent>
				<FormControl sx={{ marginTop: '20px', width: 552 }}>
					<InputLabel id="demo-multiple-checkbox-label">Tenants</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						multiple
						value={selectedTenants}
						onChange={handleChange}
						input={<OutlinedInput label="Tenants" />}
						renderValue={(selected) => selected.join(', ')}
						MenuProps={MenuProps}
					>
						{tenants.map((tenant) => (
							<MenuItem key={tenant.id} value={tenant.display}>
								<Checkbox checked={selectedTenants.indexOf(tenant.display) > -1} />
								<ListItemText primary={tenant.display} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleUpdateModal(false)} color="primary">
					Cancel
				</Button>
				<Button onClick={() => handleUpdateModal(true)} color="primary">
					Update
				</Button>
			</DialogActions>
		</Dialog>
	);
}