import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  Divider as MuiDivider,
  IconButton,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Container,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  Add as AddIcon,
  Update as UpdateIcon,
  FilterList as FilterListIcon,
  MoreVert as MoreVertIcon,
} from "@mui/icons-material";
import {
  addTenant,
  getTenants,
  Tenant,
  updateTenant,
  addChart,
  getUsers,
  User,
  updateUser,
  getTenantTypes,
  TenantType,
  deleteTenant,
} from "../apis/api";

import { spacing, SpacingProps } from "@mui/system";
import { Dispatch, State } from "../reducers";
import { TenantAddModal } from "../modals/TenantAddModal";
import {
  setTenants as setTenantsReducer,
  TenantSet,
  TenantState,
  addTenant as addTenantReducer,
  updateTenant as updateTenantReducer,
  deleteTenant as deleteTenantReducer,
} from "../reducers/tenant";
import { TenantUpdateModal } from "../modals/TenantUpdateModal";
import { AppBarHeader } from "./Appbar";

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

function descendingComparator(a: Tenant, b: Tenant, orderBy: string) {
  // @ts-ignore
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  // @ts-ignore
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: "desc" | "asc", orderBy: string) {
  return order === "desc"
    ? (a: Tenant, b: Tenant) => descendingComparator(a, b, orderBy)
    : (a: Tenant, b: Tenant) => -descendingComparator(a, b, orderBy);
}

function stableSort(
  array: Array<Tenant>,
  comparator: (a: Tenant, b: Tenant) => number
) {
  const stabilizedThis = array.map((el: Tenant, index: number) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

type HeadCell = {
  id: string;
  alignment: "left" | "center" | "right" | "justify" | "inherit" | undefined;
  label: string;
  disablePadding?: boolean;
};
const headCells: Array<HeadCell> = [
  { id: "name", alignment: "left", label: "Name" },
  { id: "display", alignment: "left", label: "Display" },
  { id: "tenantTypeId", alignment: "left", label: "Type" },
  { id: "actions", alignment: "right", label: "Actions" },
];

type EnhancedTableHeadProps = {
  order: "desc" | "asc";
  orderBy: string;
  rowCount: number;
  onRequestSort: (e: any, property: string) => void;
};
const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = (props) => {
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

type EnhancedTableToolbarProps = { numSelected: number };
const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        <Typography variant="h6" id="tableTitle">
          Tenants
        </Typography>
      </ToolbarTitle>
      <Spacer />
      <Tooltip title="Filter list">
        <IconButton aria-label="Filter list" size="large">
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

interface EnhancedTablePRops {
  tenants: TenantState[];
  setUpdateModal: Function;
  deleteTenant: Function;
  tenantTypes: TenantType[];
}

type RowMenuProps = {
  index: number;
  row: any; // Here you should replace 'any' with the actual type of 'row'
  handleClose: any;
  setUpdateModal: any;
};

function EnhancedTable({
  tenants,
  setUpdateModal,
  deleteTenant,
  tenantTypes,
}: EnhancedTablePRops): JSX.Element {
  const [order, setOrder] = React.useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState<Array<string>>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickButton = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tenants.length - page * rowsPerPage);

  const handleClick = (id: string) => {
    if (!open) history.push(`/tenants/${id}`);
  };

  const RowMenu: React.FC<RowMenuProps> = ({
    index,
    row,
    handleClose,
    setUpdateModal,
  }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClickButton = (event: any) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    return (
      <>
        <IconButton
          aria-label="more"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClickButton}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: "20ch",
            },
          }}
          sx={{
            boxShadow:
              "0px 0px 0px 1px rgba(0,0,0,-0.8),0px 0px 0px -1px rgba(0,0,0,0.14),0px 3px 7px 0px rgba(0,0,0,0.12)",
          }}
        >
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              setUpdateModal(true, row.id);
              handleClose();
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              deleteTenant(row.id);
              handleClose();
            }}
            disabled={true}
          >
            Delete
          </MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={tenants.length}
            />
            <TableBody>
              {tenants.length > 0 &&
                stableSort(tenants, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="list"
                        tabIndex={-1}
                        key={`${row.id}-${index}`}
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClick(row.id);
                        }}
                      >
                        <TableCell component="th" id={labelId} scope="row">
                          <Customer>
                            <Box>{row.name}</Box>
                          </Customer>
                        </TableCell>
                        <TableCell align="left">{row.display}</TableCell>
                        <TableCell align="left">
                          {
                            tenantTypes.filter(
                              (item) => item.id == row.tenantTypeId
                            )?.[0]?.name
                          }
                        </TableCell>
                        <TableCell align="right">
                          <RowMenu
                            index={index}
                            row={row}
                            handleClose={handleClose}
                            setUpdateModal={setUpdateModal}
                          />
                        </TableCell>
                        {/* <TableCell align="right">
                          <IconButton
                            aria-label="more"
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClickButton}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id={`basic-menu-${index}`}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: 48 * 4.5,
                                width: "20ch",
                              },
                            }}
                            sx={{
                              boxShadow:
                                "0px 0px 0px 1px rgba(0,0,0,-0.8),0px 0px 0px -1px rgba(0,0,0,0.14),0px 3px 7px 0px rgba(0,0,0,0.12)",
                            }}
                          >
                            <MenuItem
                              id={`basic-menu-item-${index}`}
                              onClick={(e) => {
                                e.stopPropagation();
                                setUpdateModal(true, row.id);
                                handleClose();
                              }}
                            >
                              Edit
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteTenant(row.id);
                                handleClose();
                              }}
                              disabled={true}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={tenants.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

interface TenantsProps {
  token: string;
  tenants: TenantState[];
}

const TenantsComponent = ({
  token,
  tenants,
  setTenantsReducer,
  addTenantReducer,
  updateTenantReducer,
  deleteTenantReducer,
}: any): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [baseTenantId, setBaseTenantId] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [updateName, setUpdateName] = useState("");
  const [updateDisplay, setUpdateDisplay] = useState("");
  const [updateTenantTypeId, setUpdateTenantTypeId] = useState("");
  const [updateImage, setUpdateImage] = useState("");
  const [users, setUsers] = useState<User[]>([]);
  const [tenantTypes, setTenantTypes] = useState<TenantType[]>([]);

  const handleAddChange = async (
    val: boolean,
    name: string,
    display: string,
    tenantTypeId: string,
    image: File,
    tenantId: string
  ) => {
    setOpenModal(false);

    if (name && display && image && tenantTypeId && val) {
      const tenant: Tenant | null = await addTenant(token, {
        name,
        display,
        tenantTypeId,
        baseTenantId: tenantId ? tenantId : baseTenantId,
        image,
      });
      if (tenant) {
        addTenantReducer(tenant);
        const baseId = tenantId ? tenantId : baseTenantId;
        // search users based on baseId
        const result = users.filter((item) => {
          if (
            item.user_metadata &&
            item.user_metadata.tenantIds &&
            item.user_metadata.tenantIds.indexOf(baseId) > -1
          ) {
            return item;
          }
        });

        if (result.length > 0) {
          // copy users
          result.forEach(async (item) => {
            //@ts-ignore
            var tenantIds = item.user_metadata.tenantIds;
            tenantIds.push(tenant.id);
            await updateUser(token, { id: item.user_id, tenantIds });
          });
        }
      }
    }
  };

  const handleUpdateChange = async (
    val: boolean,
    name: string,
    display: string,
    tenantTypeId: string,
    image: File
  ) => {
    setOpenUpdateModal(false);
    setUpdateName("");
    setUpdateDisplay("");
    setUpdateTenantTypeId("");
    setUpdateImage("");

    if (name && display && image && val) {
      const result = await updateTenant(token, updateId, {
        name,
        display,
        image,
        tenantTypeId,
        id: updateId,
      });
      const tenant = {
        id: updateId,
        display: display,
        name: name,
        image: image,
      };
      if (result) {
        updateTenantReducer(tenant);
      }
    }
  };

  useEffect((): void => {
    if (!token) return;

    const callTenants = async (): Promise<any> => {
      const tenants: Tenant[] | null = await getTenants(token);
      setTenantsReducer(tenants ? tenants : []);
      if (tenants && tenants.length > 0) {
        const result = tenants?.filter((tenant) => tenant.name == "base");
        if (result && result.length > 0) {
          setBaseTenantId(result[0].id);
        }
      }
    };

    const callUsers = async (): Promise<any> => {
      const users: User[] | null = await getUsers(token);
      if (users) {
        setUsers(users.filter((user) => user.user_metadata != null));
      }
    };

    const callTenantTypes = async (): Promise<any> => {
      const types: TenantType[] | null = await getTenantTypes(token);
      setTenantTypes(types ?? []);
    };

    callTenants();
    callTenantTypes();
    callUsers();
  }, [token]);

  useEffect((): void => {
    if (updateName && updateDisplay) {
      setOpenUpdateModal(true);
    }
  }, [updateName, updateDisplay]);

  const handleUpdateModal = (val: boolean, tenantId: string) => {
    const result = tenants.filter(
      (tenant: { id: string }) => tenant.id == tenantId
    );
    if (result && result.length > 0) {
      setUpdateId(tenantId);
      setUpdateName(result[0].name);
      setUpdateDisplay(result[0].display);
      setUpdateTenantTypeId(result[0].tenantTypeId);
      setUpdateImage("data:image/jpeg;base64," + result[0].image);
    }
  };

  const handleDeleteTenant = async (id: string) => {
    // call delete tenant api & change tenant reducer
    const result = await deleteTenant(token, id);
    if (result) {
      deleteTenantReducer(id);
    }
  };

  return (
    <div>
      <AppBarHeader />
      <Container sx={{ marginTop: "30px" }}>
        <Grid justifyContent="end" container sx={{ marginBottom: "30px" }}>
          <Grid item>
            <div>
              <Button
                variant="contained"
                sx={{ backgroundColor: "rgb(0, 131, 143)" }}
                onClick={() => setOpenModal(true)}
              >
                <AddIcon />
                New Tenant
              </Button>
            </div>
          </Grid>
        </Grid>

        <TenantAddModal
          value={openModal}
          onClick={(
            val: boolean,
            name: string,
            display: string,
            tenantTypeId: string,
            image: File,
            tenantId: string
          ) =>
            handleAddChange(val, name, display, tenantTypeId, image, tenantId)
          }
          tenants={tenants}
          types={tenantTypes}
        />

        <TenantUpdateModal
          value={openUpdateModal}
          nameProp={updateName}
          displayProp={updateDisplay}
          imageProp={updateImage}
          types={tenantTypes}
          tenantTypeProp={updateTenantTypeId}
          onClick={(
            val: boolean,
            name: string,
            display: string,
            tenantTypeId: string,
            image: File
          ) => handleUpdateChange(val, name, display, tenantTypeId, image)}
        />

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable
              tenants={tenants}
              setUpdateModal={(val: boolean, tenantId: string) =>
                handleUpdateModal(val, tenantId)
              }
              tenantTypes={tenantTypes}
              deleteTenant={(tenantId: string) => handleDeleteTenant(tenantId)}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

interface DispatchFromProps {
  setTenantsReducer: (tenants: TenantState[]) => void;
  addTenantReducer: (tenant: TenantSet) => void;
  updateTenantReducer: (tenant: TenantSet) => void;
  deleteTenantReducer: (tenantId: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  setTenantsReducer: (tenants): void => {
    dispatch(setTenantsReducer(tenants));
  },
  addTenantReducer: (tenant): void => {
    dispatch(addTenantReducer(tenant));
  },
  updateTenantReducer: (tenant): void => {
    dispatch(updateTenantReducer(tenant));
  },
  deleteTenantReducer: (tenantId): void => {
    dispatch(deleteTenantReducer(tenantId));
  },
});

const mapStateToProps = (state: State): TenantsProps => ({
  token: state.userState.token || "",
  tenants: state.tenantState,
});

export const Tenants = connect(
  mapStateToProps,
  mapDispatchToProps
)(TenantsComponent);
