export interface TenantState {
	id: string
	name: string
	display: string
	image: string
	tenantTypeId: string;
}

export interface TenantSet {
	id: string
	name: string
	display: string
	image: string
	tenantTypeId: string;
}

export const SET_TENANTS = 'SET_TENANTS'
export interface SetTenants {
	type: typeof SET_TENANTS
	payload: TenantSet[]
}

export const setTenants = (payload: TenantSet[]): SetTenants => ({
	type: SET_TENANTS,
	payload
})

export const ADD_TENANT = 'ADD_TENANT'
export interface AddTenant {
	type: typeof ADD_TENANT
	payload: TenantSet
}

export const addTenant = (payload: TenantSet): AddTenant => ({
	type: ADD_TENANT,
	payload
})

export const UPDATE_TENANT = 'UPDATE_TENANT'
export interface UpdateTenant {
	type: typeof UPDATE_TENANT
	payload: TenantSet
}

export const updateTenant = (payload: TenantSet): UpdateTenant => ({
	type: UPDATE_TENANT,
	payload
})

export const DELETE_TENANT = 'DELETE_TENANT'
export interface DeleteTenant {
	type: typeof DELETE_TENANT
	payload: string
}

export const deleteTenant = (payload: string): DeleteTenant => ({
	type: DELETE_TENANT,
	payload
})

export const tenantReducer = (
	state: TenantState[] = [],
	action: SetTenants | AddTenant | UpdateTenant | DeleteTenant
): TenantState[] => {
	switch (action.type) {
		case SET_TENANTS:
			return action.payload
		case ADD_TENANT:
			return [...state, action.payload]
		case UPDATE_TENANT:
			return state.map(tenant =>
				tenant.id === action.payload.id
					? { ...tenant, ...action.payload }
					: tenant);
		case DELETE_TENANT:
			return state.filter(tenant => tenant.id !== action.payload);
		default:
			return state
	}
}