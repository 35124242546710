import { useState, useEffect, ChangeEvent } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";

import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import { TenantType, getTenantTypes } from "../apis/api";

interface TenantUpdateModalPRops {
  value: boolean;
  nameProp: string;
  displayProp: string;
  imageProp: string;
  types: TenantType[];
  tenantTypeProp: string;
  onClick: Function;
}
export function TenantUpdateModal({
  value,
  nameProp,
  displayProp,
  imageProp,
  types,
  tenantTypeProp,
  onClick,
}: TenantUpdateModalPRops): JSX.Element {
  const [name, setName] = useState("");
  const [display, setDisplay] = useState("");
  const [tenantTypeId, setTenantTypeId] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  useEffect((): void => {
    if (nameProp && displayProp) {
      setName(nameProp);
      setDisplay(displayProp);
      setTenantTypeId(tenantTypeProp);
      setPreviewImage(imageProp);
      setSelectedFile(base64ToFile(imageProp, "logo.jpg"));
    }
  }, [nameProp, displayProp]);

  const base64ToFile = (base64Str: string, filename: string) => {
    let arr = base64Str.split(",");
    // @ts-ignore
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const handleUpdateModal = (flag: boolean) => {
    if (flag) {
      onClick(flag, name, display, tenantTypeId, selectedFile);
    } else {
      onClick(flag, null, null, null, null);
    }
    setName("");
    setDisplay("");
    setTenantTypeId("");
    setSelectedFile(null);
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setTenantTypeId(event.target.value as string);
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
      setPreviewImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  return (
    <Dialog
      open={value}
      onClose={() => handleUpdateModal(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Edit Tenant</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          value={name}
          fullWidth
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setName(event.target.value)
          }
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          autoFocus
          margin="dense"
          id="display"
          label="Display"
          type="text"
          value={display}
          fullWidth
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setDisplay(event.target.value)
          }
          InputLabelProps={{ shrink: true }}
        />
        <FormControl fullWidth sx={{ mt: "10px", mb: "10px" }}>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tenantTypeId}
            label="Type"
            onChange={handleTypeChange}
          >
            {types.map((type, index) => {
              return (
                <MenuItem value={type.id} key={index}>
                  {type.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "10px",
            padding: "10px",
            border: "1px solid rgba(0,0,0,0.2)",
            borderRadius: "6px",
          }}
        >
          {previewImage && (
            <img
              src={previewImage}
              alt=""
              style={{ width: "100px", marginBottom: "10px" }}
            />
          )}
          <input
            type="file"
            id="contained-button-file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              sx={{ textTransform: "capitalize" }}
            >
              <CloudUploadIcon />
              &nbsp;Upload Logo
            </Button>
          </label>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleUpdateModal(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleUpdateModal(true)} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
