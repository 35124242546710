// @ts-nocheck - may need to be at the start of file
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from './auth0_wrapper'

export const onRedirectCallback = (appState?: { targetUrl: string }): void => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH_DOMAIN}
    client_id={process.env.REACT_APP_AUTH_CLIEND_ID}
    grant_type={process.env.REACT_APP_AUTH_GRANT_TYPE}
    redirect_uri={`${window.location.origin}`}
    onRedirectCallback={onRedirectCallback}
    audience={process.env.REACT_APP_AUTH_AUDIENCE}
  >
    <App />
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
