import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { useAuth0 } from '../auth0_wrapper'
import { Dispatch } from '../reducers'
import { setUser, UserSet } from '../reducers/user'

const PrivateRouteComponent = ({
  component: Component,
  path,
  setTheUser,
  ...rest
}: any): JSX.Element => {
  const {
    isAuthenticated,
    loginWithRedirect,
    getTokenSilently,
    user
  } = useAuth0()

  useEffect((): void => {
    const fn = async (): Promise<void> => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: { targetUrl: path }
        })
      } else {
        if (getTokenSilently && user) {
          const token = await getTokenSilently()
          setTheUser({ ...user, token })
        }
      }
    }
    fn()
  }, [
    isAuthenticated,
    loginWithRedirect,
    path,
    getTokenSilently,
    user,
    setTheUser,
  ])

  const render = (props: any): JSX.Element | null =>
    isAuthenticated === true ? <Component {...props} /> : null

  return <Route path={path} render={render} {...rest} />
}

interface DispatchFromProps {
  setTheUser: (user: UserSet) => void
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  setTheUser: (user): void => {
    dispatch(setUser(user))
  }
})

export const PrivateRoute = connect(
  null,
  mapDispatchToProps
)(PrivateRouteComponent)
