import React from 'react';
import './App.css';
import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PrivateRoute } from './components/PrivateRoute'
import { reducers } from './reducers'
import { useAuth0 } from './auth0_wrapper'
import { Tenants } from './components/Tenants';
import { TenantDetail } from './components/TenantDetail';

const sagaMiddleware = createSagaMiddleware()

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

function App() {
  const { loading } = useAuth0()

  if (loading) {
    return <>loading...</>
  }

  return (
    <Router>
      <Provider store={store}>
        <PrivateRoute exact path="/tenants" component={Tenants} />
        <PrivateRoute exact path="/tenants/:id" component={TenantDetail} />
        <PrivateRoute exact path="/" component={Tenants} />
      </Provider>
    </Router>
  );
}

export default App;
